<template>
	<div class="bg-F7F7F7">
		<div class="relative" style=" height: 430px;">
			<img class="bg-pic w100 display-block" src="@/assets/img/login_bg.png" alt="" />
			<div class="absolute top0 left0 right0 bottom0">
				<div class="w1200 top-main paddt85 display-flex">
					<img class="img_02 display-block" :src="dataDetail.image" alt="" />
					<div class="flex-1 marl22">
						<div class="fz28 co-FFFFFF font-blod">{{dataDetail.title || ''}}</div>
						<div class="fz20 co-FFFFFF mart14">剩余名额：{{dataDetail.surplus_num || 0}}</div>
					</div>
				</div>
			</div>
		</div>

		<div class="price h120 w1200 relative" v-if="type==1">
			<div
				class="absolute left0 top0 right0 bottom0 justify-content-center-between boderra4 bg-white price-main paddlr30">
				<div class="align-items-end">
					<span class="fz56 l46 co-010085">￥{{dataDetail.price || '0.00'}}</span>
					<span class="fz24 co-333333 display-block marl8">元</span>
				</div>
				<div class="down-btn center fz16 co-010085 font-blod cure-pointer" @click="toSure">立即购买</div>
			</div>
		</div>

		<div class="bg-F7F7F7 paddt20">
			<div class="w1200 main bg-white display-flex flex-column">
				<div v-html="dataDetail.content" class="html-box  ">
				</div>
				<div class="flex-1">
					<video-player class="video-player   vjs-custom-skin" ref="videoPlayer" :playsinline="true"
						:options="playerOptions"  />
				</div>
				<div class="mart10 center flex-column">
					<div class="w1200 bg-white">
						<div class="padd21">
							<div class="catalogue">
								<div class="" v-for="(item, i) in dataDetail.chapters" :key="i">
									<div class="paddlr20 h44 align-items-center fz16 co-333333 bg-F7F7F7">{{item.title}}
									</div>
									<div class="paddlr20 h44 align-items-center fz16 cure-pointer  "
										:class="{'acitve':videoId == e.id}" v-for="(e,index) in item.children"
										:key="index">
										<img v-if="videoId == e.id" class="cd_01 display-block "
											src="@/assets/img/cd_02.png" alt="" />
										<img v-else class="cd_01 display-block" src="@/assets/img/cd_01.png" alt="" />
										<span class="fz16 co-999999 display-block marl14 "
											:class="{'spans':videoId == e.id}"
											@click="chooseVedio(e)">{{e.title}}</span>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-loading.fullscreen.lock="fullscreenLoading"></div>
	</div>
	
</template>

<script>
	import {
		useRoute
	} from 'vue-router'
	
 
	export default {
		name: "MaterialDetail",
		data() {
			return {
				type:1,
				state: 1, // 列表加载状态
				page: 0, // 分页
				loading: false, // 接口加载状态
				list: [], // 列表
				dataDetail: {}, //总数据
				commentList: [], //评论数据
				detailId: '', //课程id
				videoId: '', //选中视频的id
				playerOptions: {			
					playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
					autoplay: false, //如果true,浏览器准备好时开始回放。
					muted: false, // 默认情况下将会消除任何音频。
					loop: false, // 导致视频一结束就重新开始。
					preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
					language: 'zh-CN',
					aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
					fluid: false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
					sources: [{
						type: "video/mp4", // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
						src: "" // url地址
					}],
					poster: "", // 你的封面地址				
					notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
					controlBar: {
					          //当前时间和持续时间的分隔符
					          timeDivider: true,
					          //显示持续时间
					          durationDisplay: true,
					          //是否显示剩余时间功能
					          remainingTimeDisplay: false,
					          //全屏按钮
					          fullscreenToggle: true,
					          //播放暂停按钮
					          playToggle: true,
					          //音量控制
					          volumeMenuButton: false,
					          //当前播放时间
					          currentTimeDisplay: true,
					          //点播流时，播放进度条，seek控制
					          progressControl: true,
					          //直播流时，显示LIVE
					          liveDisplay: true,
					          //播放速率，当前只有html5模式下才支持设置播放速率
					          playbackRateMenuButton: true
					        }
				},
				fullscreenLoading:true,//加载中

			}
		},
		created() {

			let id = useRoute().query.id;
			this.type = useRoute().query.type;
			console.log(this.type)
			this.detailId = id;
			this.$req({
				method: 'get',
				url: '/api/course/detail',
				data: {
					id: this.detailId,
				},
				success: res => {
						this.fullscreenLoading =false;
					console.log(res, "服务详情")
					this.dataDetail = res
					this.videoUrl = this.dataDetail.video;
					this.playerOptions.sources[0].src= this.videoUrl
				},
				fail: error => {
						this.fullscreenLoading =false;
				}
			});

			this.getComment();
			console.log(this)
		},
		methods: {
			getComment() {
				console.log('评论详情')
			},
			toSure() {
				console.log(this.dataDetail.is_buy,'类型')
				if (this.dataDetail.is_buy == 2) {
					this.$message('已存在待支付订单，可在我的订单中查看')
					return
				} 
				let  orderDetails = this.dataDetail
				
				this.$router.push({
					path: '/SubmitOrder',
					query: {
						type: 3,
						orderDetail:JSON.stringify(orderDetails)
					}
				})
			},
			chooseVedio(e) {
				// 选择视频

				this.$refs.videoPlayer.player.pause();
				this.playerOptions.sources[0].src = e.video;
				this.videoId = e.id;				
				setTimeout(() => {
					this.$refs.videoPlayer.player.play()
				}, 500)
			},
		}
	}
</script>

<style >
	
	.html-box>>>img {

		display: block;
		margin: 5px auto;
		width: 100% !important;
		height: auto !important;
	}
	.video-js .vjs-slider {
	    background-color: #ffffff;
	  }
	.vjs-custom-skin > .video-js .vjs-play-progress, .vjs-custom-skin > .video-js .vjs-volume-level {
	   background-color: #DCFF03;
	  }
	
	.acitve {
		background: #F9FFD6;

	}

	.spans {
		color: #010085 !important;
	}

	.video {
		width: 100%;
		height: 650px;
		border-radius: 8rpx;
		position: relative;
		display: inline-block;
		text-align: center;
		line-height: 100px;
		border: 1px solid transparent;
		border-radius: 4px;
		overflow: hidden;
		background: #fff;
		position: relative;
		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
		margin-right: 4px;
	}

	.html-box>>>p {

		width: 100%;
		word-break: break-word;
		font-size: 18px;
		font-weight: 500;
		color: #000000;
		margin: 30px 0;
	}

	.html-box>>>span {

		max-width: 100%;
		word-break: break-word;
		font-size: 18px;
		font-weight: 500;
		color: #000000;
		margin: 30px 0;
	}

	.bg-pic {
		height: 450px;
		filter: blur(16px);
		-webkit-filter: blur(15px);
	}

	.top-main {
		margin: 0 auto;


	}

	.img_02 {
		width: 285px;
		height: 220px;
		border-radius: 8px;
	}

	.price {
		margin: 0 auto;
		margin-top: -60px;


	}

	.price-main {
		z-index: 10;
	}

	.down-btn {
		width: 158px;
		height: 50px;
		background: #DCFF03;
		border-radius: 4px;
	}

	.main {
		margin: 0 auto;


	}

	.main-pic {
		height: 450px;
	}

	.main-content {
		padding-left: 20px;
		padding-right: 19px;
	}

	.textarea {
		padding: 10px;
		height: 54px;
		outline: none;
		background: #F7F7F7;
		border: 0;
		resize: none;
	}

	.head-pic-01 {
		width: 24px;
		height: 24px;
		border-radius: 50%;
	}

	.de_08 {
		width: 20px;
		height: 20px;
	}

	.submit-btn {
		width: 72px;
		height: 28px;
		background: #DCFF03;
		border-radius: 3px;
	}

	.head-pic-02 {
		width: 30px;
		height: 30px;
		border-radius: 50%;
	}

	.comment-like {
		width: 20px;
		height: 20px;
	}

	.catalogue {
		width: 1160px;
		border: 1px solid #E6E6E6;


	}

	.cd_01 {
		width: 22px;
		height: 22px;
	}
</style>
